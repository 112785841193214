const logotext = "Luke";
const meta = {
    title: "Luke Colvin",
    description: "Hey I’m Luke Colvin, Software Dev Student _ Currently going to school full time at Olivet Nazarene University",
};

const introdata = {
    title: "Hey, I’m Luke!",
    animated: {
        first: "I love to code",
        second: "I code stuff... sometimes...",
        third: "Where am I?",
        fourth: "I build websites",
        fifth: "I make stuff",
        sixth: "I strive to make an impact on the world",
        seventh: "Certified semicolon enthusiast",
        eighth: "Debugging is my cardio",
        ninth: "Will code for coffee",
        tenth: "404: Bio not found",
        eleventh: "I speak fluent pseudocode",
        twelfth: "I turn coffee into code",
        thirteenth:"Wheres Ryan?",
        fourteenth:"Have you mooed today?"
    },
    your_img_url: "https://github.com/lucolvin/public-pics-hosting/blob/main/my_ugly_mug.PNG?raw=true",
};

const dataabout = {
    title: "A bit about me...",
    aboutme: "Hello there! My name is Luke Colvin, I am  currently a Comp Sci student at Olivet Nazarene University. I'm passionate about technology and outreach for the community. I am actively involved in volunteer work as a web developer for the Center for Cybersecurity Outreach at ONU.",
};
const worktimeline = [{
        jobtitle: "Web Application Developer",
        where: "Olivet Nazarene University",
        date: "Sep 2023 - Present",
    },
];

const volunteering = [{
        jobtitle: "Web Developer",
        where: "CCO at ONU",
        date: "Aug 2023 - Present",
    },
];

const school = [{
    name: "Olivet Nazarene University",
    degree: "BS, Computer Science",
    gpa: "GPA: 4.0",
    minor: "Minor in Cybersecurity",      
    when: "2023 - Present",
    },
];

const skills = [
    {
        name: "PHP",
        value: 60,
    },
    {
        name: "Javascript",
        value: 80,
    },
    {
        name: "HTML",
        value: 80,
    },
    {
        name: "CSS",
        value: 80,
    },
    {
        name: "Python",
        value: 85,
    },
    {
        name: "SQL",
        value: 70,
    },
    {
        name: "Linux",
        value: 75,
    },
    {
        name: "Windows Server",
        value: 60,
    },
];

const skillsLearning = [
    {
        name: "C++",
        value: 40,
    },
    {
        name: "React",
        value: 30,
    },
    {
        name: "Java",
        value: 45,
    },
];

const services = [];

const projectsUrl = 'https://raw.githubusercontent.com/lucolvin/Personal-Website-Projects/main/projects.txt';

async function fetchProjectsFile(url) {
    const response = await fetch(url);
    const text = await response.text();
    return text;
}

function parseProjectsData(text) {
    const projects = [];
    const entries = text.split('\n\n');
    entries.forEach(entry => {
        const lines = entry.split('\n');
        const project = {};
        lines.forEach(line => {
            if (line.trim()) { // Check if the line is not empty
                const [key, value] = line.split(': ');
                if (key && value) { // Check if both key and value are present
                    project[key.trim()] = value.replace(/"/g, '').trim();
                }
            }
        });
        if (Object.keys(project).length > 0) { // Check if the project object is not empty
            projects.push(project);
        }
    });
    return projects;
}

async function getProjects() {
    const text = await fetchProjectsFile(projectsUrl);
    const projects = parseProjectsData(text);
    return projects;
}

const contactConfig = {
    YOUR_EMAIL: "luke@lukecolvin.me",
    YOUR_FONE: "",
    description: "Feel free to reach out to me and I'll respond ASAP!",
};

const socialProfiles = {
    github: "https://github.com/lucolvin",
    linkedin: "https://www.linkedin.com/in/lucolvin/",
    twitter: "https://twitter.com/CodebyLuke",
};

export {
    meta,
    dataabout,
    getProjects,
    worktimeline,
    volunteering,
    school,
    skills,
    skillsLearning,
    services,
    introdata,
    contactConfig,
    socialProfiles,
    logotext,
};