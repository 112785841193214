import React, { useEffect, useState } from "react";
import { FaMoon } from "react-icons/fa";
import { FaSun } from "react-icons/fa";


const Themetoggle = () => {
  const [theme, settheme] = useState(localStorage.getItem("theme"));
  const themetoggle = () => {
    settheme(theme === "dark" ? "light" : "dark");
  };
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme ); 
  }, [theme]);
  // return (
  //   // <div className="nav_ac" onClick={themetoggle} title="Flash bomb warning! Dark mode users beware!">
  //   //   {theme === "dark" ? <FaSun /> : <FaMoon />}
  //   // </div>
  // );
};

export default Themetoggle;
